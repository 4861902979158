import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { RecoilRoot } from "recoil";

import { App } from "./app";
import { GameErrorPage } from "./modules/room/components/game-error-page";
import { ErrorBoundary } from "./modules/ui/components/error-boundary";
import { setupSentry } from "./modules/utils/setup-sentry";

setupSentry();

// Added because of twilio-video sdk
window["global"] = window;

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("root element not found");

const root = ReactDOM.createRoot(rootElement);

root.render(
  <StrictMode>
    <RecoilRoot>
      <ErrorBoundary fallback={<GameErrorPage />}>
        <App />
      </ErrorBoundary>
    </RecoilRoot>
  </StrictMode>
);

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { SENTRY, VERSION } from "../../config";

export function setupSentry() {
  if (import.meta.env.VITE_APP_ENVIRONMENT === "development") return;

  Sentry.init({
    release: "dejarik@" + VERSION,
    environment: import.meta.env.VITE_APP_ENVIRONMENT,
    dsn: SENTRY.dsn,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: SENTRY.sampleRate,
  });
}

import { ComponentPropsWithoutRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";

const FullHeightComponent = styled.div`
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`;

export function FullHeight({
  children,
  ...props
}: ComponentPropsWithoutRef<"div">) {
  useEffect(() => {
    function handleResize() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <FullHeightComponent {...props}>{children}</FullHeightComponent>;
}

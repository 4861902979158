import { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { debugState } from "../../debug/state/debug";

export function useToggleDebugMode() {
  const setIsDebugModeEnabled = useSetRecoilState(debugState);

  const toggleDebugMode = () => {
    setIsDebugModeEnabled((debugMode) => !debugMode);
  };

  const enableDebugMode = () => {
    setIsDebugModeEnabled(true);
  };

  const disableDebugMode = () => {
    setIsDebugModeEnabled(false);
  };

  useEffect(() => {
    //@ts-ignore
    window["toggleDebugMode"] = toggleDebugMode;
    //@ts-ignore
    window["enableDebugMode"] = enableDebugMode;
    //@ts-ignore
    window["disableDebugMode"] = disableDebugMode;

    () => {
      //@ts-ignore
      delete window["toggleDebugMode"];
      //@ts-ignore
      delete window["enableDebugMode"];
      //@ts-ignore
      delete window["disableDebugMode"];
    };
  }, []);
}

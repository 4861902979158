import React, { Children, Fragment } from "react";
import { useRecoilValue } from "recoil";
import { translationState } from "../state/language";
import { TranslationKey, Translation as TranslationType } from "../types";
import { replaceWithNode } from "./replace-with-node";

function get(
  translation: TranslationType,
  path: TranslationKey,
  defaultValue: unknown
): string {
  return path.split(".").reduce(
    //@ts-ignore
    (o, p) => (o ? o[p] : defaultValue),
    translation
  ) as unknown as string;
}

export interface TranslationProps {
  k: TranslationKey;
  [replaceKey: string]: React.ReactNode;
}

export function Translation({ k, ...replacementKeys }: TranslationProps) {
  const translation = useRecoilValue(translationState);

  let translationString: React.ReactNode[] = [
    get(translation, k, "MISSING KEY"),
  ];

  Object.entries(replacementKeys).forEach(([key, value]) => {
    translationString = replaceWithNode(translationString, key, value);
  });

  return (
    <>
      {Children.map(translationString, (child, index) => (
        <Fragment key={`translation=${index}`}>{child}</Fragment>
      ))}
    </>
  );
}

import { SupportedLanguage } from "./supported-languages";

export function getDefaultLanguage(supportedLanguages: SupportedLanguage[]) {
  const supportedLanguage = navigator.languages.reduce<
    SupportedLanguage | undefined
  >((result, lang) => {
    if (result) return result;

    const supportedLang = supportedLanguages.find(
      (supportedLanguage) => lang.indexOf(supportedLanguage) > -1
    );

    return supportedLang;
  }, undefined);

  return supportedLanguage;
}

import { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./app.css";
import { LoadingApp } from "./modules/ui/pages/loading-app";
import { IS_PRODUCTION_ENVIRONMENT } from "./config";
import { useToggleDebugMode } from "./modules/global/hooks/use-toggle-debug-mode";

const WelcomePage = lazy(() => import("./modules/home/pages"));
const RoomPage = lazy(() => import("./modules/room/pages"));
const ReplayPage = lazy(
  () => import("./modules/debug/pages/replayable-session")
);
const ReplayableSessionsPage = lazy(
  () => import("./modules/debug/pages/replayable-sessions")
);
const FourOFourPage = lazy(() => import("./modules/home/pages/404"));

export function App() {
  useToggleDebugMode();

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Suspense fallback={<LoadingApp />}>
              <WelcomePage />
            </Suspense>
          }
        />
        <Route
          path="/room/:roomId"
          element={
            <Suspense fallback={<LoadingApp />}>
              <RoomPage />
            </Suspense>
          }
        />
        {!IS_PRODUCTION_ENVIRONMENT && (
          <>
            <Route
              path="/replay-sessions"
              element={
                <Suspense fallback={<LoadingApp />}>
                  <ReplayableSessionsPage />
                </Suspense>
              }
            />
            <Route
              path="/replay-sessions/:sessionId"
              element={
                <Suspense fallback={<LoadingApp />}>
                  <ReplayPage />
                </Suspense>
              }
            />
          </>
        )}

        <Route
          path="*"
          element={
            <Suspense fallback={<LoadingApp />}>
              <FourOFourPage />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  );
}

import { AtomEffect, DefaultValue } from "recoil";

const storageEffect =
  (storage: Storage) =>
  <State>(key: string): AtomEffect<State | undefined> =>
  ({ setSelf, onSet }) => {
    const savedValue = storage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue instanceof DefaultValue) {
        storage.removeItem(key);
      } else {
        storage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const localStorageEffect = storageEffect(localStorage);
export const sessionStorageEffect = storageEffect(sessionStorage);

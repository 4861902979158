import { faGhost } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion, Variants } from "framer-motion";
import { ComponentProps } from "react";

const loadingPageVariants: Variants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
};

export function LoadingPage({ className, children }: ComponentProps<"div">) {
  return (
    <motion.div
      initial="initial"
      animate="enter"
      exit="exit"
      variants={loadingPageVariants}
      className={`${className} w-full h-full flex flex-col justify-between items-center pt-32 pb-20`}
    >
      <div className="text-blue-600 animate-bounce ">
        <FontAwesomeIcon icon={faGhost} size="2x" />
      </div>
      <div className="text font-semibold text-gray-800">{children}</div>
    </motion.div>
  );
}

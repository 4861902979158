import { version, author } from "../package.json";

export const VERSION = version;

export const IS_PRODUCTION_ENVIRONMENT =
  import.meta.env.VITE_APP_ENVIRONMENT === "production";

export const SENTRY = {
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  sampleRate: parseFloat(import.meta.env.VITE_APP_SENTRY_SAMPLE_RATE),
};

export const CREATOR = {
  name: author,
  link: "https://maximilianbeyer.dev",
};

export const firebaseAppConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: import.meta.env.VITE_APP_FIREBASE_DB_URL,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_APP_FIREBASE_MEASUREMENT_ID,
} as const;

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComponentProps } from "react";

export function ErrorPage({ className, children }: ComponentProps<"div">) {
  return (
    <div
      className={`${className} h-screen flex flex-col justify-between items-center pt-32 pb-24`}
    >
      <div className="text-red-500">
        <FontAwesomeIcon icon={faExclamationTriangle} size="2x" />
      </div>
      <div className="text font-semibold text-gray-800 text-center max-w-sm">
        {children}
      </div>
    </div>
  );
}

import { atom, selector } from "recoil";
import { getDefaultLanguage } from "../utils/get-default-language";
import {
  SupportedLanguage,
  SUPPORTED_LANGUAGES,
} from "../utils/supported-languages";

export const languageState = atom<SupportedLanguage>({
  key: "localization/language",
  default:
    getDefaultLanguage(
      Object.keys(SUPPORTED_LANGUAGES) as SupportedLanguage[]
    ) || "en",
});

export const translationState = selector({
  key: "localization/translation",
  get: ({ get }) => {
    const lang = get(languageState);

    return SUPPORTED_LANGUAGES[lang];
  },
});

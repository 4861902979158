export const english = {
  loading: {
    headline: "Loading",
  },
  welcome: {
    headline: "Start",
    subtitle:
      "Start a session, invite your friends, play games and have a great time!",
  },
  error: {
    headline: "If you are reading this. I done fucked up.",
    hint: "Unfortunately i can not give you any meaningful help at this time.",
  },
  pageNotFound: {
    headline: "This is not the page you are looking for.",
    linkHomeLabel: "Home",
  },
  room: {
    loading: {
      headline: "Preparing room",
    },
    playerIsJoining: {
      headline: "Joining room",
    },
    loadingGame: {
      headline: "Loading game",
    },
    waiting: {
      video: {
        connecting: "Connecting",
      },
      readyButton: {
        label: "Ready",
        subtitle: "Press ready when all players have joined the room.",
      },
      notEnoughPlayersWarning: {
        explanation: "Not enough players in the room to play.",
      },
      inviteButton: {
        label: "Invite",
        subtitle: "Invite others to be part of the fun.",
      },
      waitingForOtherPlayersNotice: {
        explanation: "Waiting for ${number} players to be ready.",
      },
    },

    gameEnd: {
      waitingForOthers:
        "Wait until others have also voted to play another game or have left the room",
    },
  },
  captureInformation: {
    headline: "Join the room",
    invitedBy: "${name} has invited you to join this room.",
    description:
      "Please provide your name and enable your camera before you join the room.",
    explanationLabel: "What is this?",
    enableVideoLabel: "Enable Video",
    deniedVideoPermissionWarning:
      "You have denied the permission to use your camera. Please enable them to join the room.",
    submitLabel: "Join",
  },
  games: {
    werwolf: {
      roles: {
        werwolf: "Werwolf",
        seer: "Seer",
        mage: "Mage",
        cupid: "Cupid",
        hunter: "Hunter",
        villager: "Villager",
      },
      loading: {
        headline: "Preparing Game",
      },
      announceDeath: {
        roleReveal: "${name} was a ${role}.",
        loverReveal: "${name} was also the lover of ${loverName}.",
      },
      opening: {
        title: "Role reveal",
        basic: {
          gameExplanation:
            "Be aware, in this game your fellow players might lie to you to gain your trust.",
          submitLabel: "Start",
          questLabel: "Quest",
          abilitiesLabel: "Abilities",
        },
        spectator: {
          title: "You are a spectator",
          explanation:
            "Unfortunately you have joined to late to be part of this round. You can still spectate and wait until the game is over to start playing with your friends.",
        },
        seer: {
          title: "You are the Seer!",
          explanationsQuest:
            "Your quest is to seek out the werewolves and lynch them!",
          explanationAbilities:
            "You can use your power each night to reveal the true nature a fellow villager.",
        },
        cupid: {
          title: "You are Cupid!",
          explanationsQuest:
            "Your quest is to seek out the werewolves and lynch them!",
          explanationAbilities:
            "You shoot your arrow to bind together two hearts. If one shall perish, so must the other; to win, both must survive.",
        },
        hunter: {
          title: "You are a Hunter!",
          explanationsQuest:
            "Your quest is to seek out the werewolves and lynch them!",
          explanationAbilities:
            "If you should perish, one last arrow shall make its mark.",
        },
        villager: {
          title: "You are a Villager!",
          explanationsQuest:
            "Your quest is to seek out the werewolves and lynch them!",
          explanationAbilities: "A villager has no special abilities.",
        },
        wolf: {
          title: "You are a Werewolf!",
          explanationsQuest:
            "Your quest to live amongst the villagers undetected!",
          otherWerewolvesSingular: " Your fellow Werewolf is ${werewolf}.",
          otherWerewolvesPlural: " Your fellow Werewolves are ${werewolves}.",
          explanationAbilities:
            "At night your true nature calls and you must claim the life of an innocent victim.",
        },
        mage: {
          title: "You are the Mage!",
          explanationsQuest:
            "Your quest is to seek out the werewolves and lynch them!",
          explanationAbilities:
            "Use your powers once per game - once to save, once to claim.",
        },
        waitingForOthers: {
          instructions: "Wait for the others to start the game.",
        },
      },
      mayorVote: {
        title: "Mayor vote",
        voting: {
          instructions:
            "The village comes together to vote for their new Mayor.\n In the event of a tie the Mayor will use their wise judgement to decide the fate of the accused. Should they perish, they will appoint their own successor.",
          hint: "Pick the player you wish to vote for and tap vote",
          waitForOthers: {
            instructions: "Your ballot has been cast! Wait for the others.",
          },
          submitLabel: "Vote",
        },
        result: {
          instructions:
            "The votes are counted. The villagers have chosen ${mayor} as their Mayor.",
          votesHeadline: "Votes",
          submitLabel: "Continue",
          waitForOthers: {
            instructions: "Waiting for others to end the turn.",
          },
        },
      },
      wolves: {
        title: "Werewolves",
        voting: {
          instructions: "You awaken and stalk your next victim!",
          hint: "Pick the player you wish to vote for and tap kill",
          submitLabel: "Kill",
        },
        waitingForOthers: {
          instructions: "Waiting for other Werewolves to make their decision.",
        },
        outcome: {
          instructions:
            "The werewolves have selected ${victim} as their victim.",
          submitLabel: "Continue",
          waitingForOthersHint: "Wait for the others to end their turn.",
        },
        waitForWolves: {
          instructions:
            "The sun sets and the village is consumed by darkness. The werewolves awaken and stalk their victim.",
        },
      },
      seer: {
        title: "Seer",
        voting: {
          instructions: "You awaken to seek out a werewolf.",
          hint: "Pick a player to see if they are a werewolf.",
          submitLabel: "Vote",
        },
        result: {
          isWerwolf: "You have selected ${name}. ${name} is a werewolf.",
          isNotWerwolf: "You have selected ${name}. ${name} is not a werewolf.",
          submitLabel: "Continue",
        },
        waitForSeer: {
          instructions: "The Seer awakens to seek out a werewolf!",
          hint: "Wait until the Seer is done with their turn",
        },
        deadSeer: {
          instructions:
            "The seer has perished and with them the ability to unmask a werewolf!",
          hint: "The game will automatically continue.",
        },
      },
      mage: {
        title: "Mage",
        saveWolvesVictim: {
          instructions:
            "You awaken and learn who the victim is. ${victim} is the victim. Do you wish use your ability to save them?",
          hint: "You are only able to use your saving ability once.",
          yesSubmitLabel: "Yes",
          noSubmitLabel: "No",
        },
        usedSavePower: {
          instructions:
            "You awaken, but you have already used your power to spare a victim.",
          submitLabel: "Continue",
        },
        result: {
          savingAbility: {
            alreadyUsed: "You could not save the victim from death.",
            used: "You have used your powers to save the victim.",
            notUsed: "You have decided not to spare the victim.",
          },
          poisonAbility: {
            alreadyUsed:
              "You have already used your death potion on another victim",
            used: "You have used your poison on ${name}, they will die a horrible death",
            unused: "You have saved your poison for another night",
          },
          submitLabel: "Continue",
        },
        poisonDecision: {
          instructions: "Does the Mage wish to make use of their death potion?",
          hint: "You are only able to use your poison ability once.",
          yesSubmitLabel: "Yes",
          noSubmitLabel: "No",
        },
        poisonAbilityHasBeenUsed: {
          instructions: "You have no death potions left.",
          submitLabel: "Continue",
        },
        poisonSelect: {
          instructions: "Who does the Mage wish to give the potion to?",
          hint: "Select the player you wish to eliminate and tap poison.",
          submitButton: "Poison",
        },
        waitForMage: {
          instructions: "The Mage awakens.",
          hint: "Wait until the Mage is done doing Mage things",
        },
        deadMage: {
          instructions:
            "The Mage has perished and can no longer save nor poison anybody.",
          hint: "The game will automatically continue.",
        },
      },
      cupid: {
        title: "Cupid",
        pickLovers: {
          instructions:
            "You awaken to take aim and bind two people's fates! If one shall perish, so must the other; to win, both must survive.",
          hint: "Select two lovers, you can select yourself",
          submitLabel: "Select",
        },
        outcome: {
          instructions:
            "Your arrow meets its mark and binds together ${loverOne} and ${loverTwo}!",
          submitLabel: "Continue",
        },
        waitForCupid: {
          instructions:
            "Cupid awakens to string their bow and bind together two hearts. If one shall perish, so must the other; to win, both must survive.",
          hint: "Wait until Cupid is done doing Cupid things.",
        },
      },
      lovers: {
        title: "Lovers",
        loversMeet: {
          instructions:
            "You and your lover awaken and meet eyes for the first time. Your quest now is to work together to stay alive. If one shall perish, so must the other; to win, both must survive.",
          hint: "Strategise now, you will not meet again until the end of the game",
          submitLabel: "Continue",
        },
        waitForLovers: {
          instructions: "The lovers awaken and see each other.",
          hint: "Wait for them to finish their turn.",
        },
      },
      morning: {
        title: "Morning",
        nightOver: {
          instructions:
            "Dawn breaks over the sleepy village. The long night is over.",
          submitLabel: "Continue",
        },
        wolvesVictim: {
          mageNotUsedSaveAbility:
            "You see a crowd gathering in the village square. The Mage has chosen to not use their save power and another innocent life was claimed during the night. The werewolves' victim was ${name}!",
          mageUsedSaveAbility:
            "The wise and powerful Mage has used their powers to save an innocent victim from death.",
          mageHasAlreadyUsedSaveAbility:
            "You see a crowd gathering in the village square. The Mage could not save another innocent life from being claimed during the night. The werewolves' victim was ${name}!",

          submitLabel: "Continue",
        },
        magePoison: {
          mageNotUsedPoisonAbility:
            "The Mage decided not to make use of their death potion last night.",
          mageUsedPoisonAbility:
            "In a desperate attempt to kill a werewolf and save the village, the Mage has decided to make use of their death potion. ${name} was killed by it!",
          mageHasAlreadyUsedPoisonAbility:
            "The Mage was unable to use their death potion, as it has already been used.",
          submitLabel: "Continue",
        },
        waitForOthers: {
          instructions: "Wait for others to end the turn.",
        },
      },
      hunter: {
        title: "Hunter",
        lastShot: {
          instructions:
            "With your dying breath, you string your arrow and pick your victim.",
          hint: "Pick the player you wish to kill.",
          submitLabel: "Kill",
        },
        waitForHunter: {
          instruction:
            "With their dying breath, ${hunter} strings their arrow and picks their mark.",
          hint: "Wait for the hunter to pick their victim.",
        },
        outcome: {
          instructions: "${hunter} shoots and kills ${name}.",
          submitLabel: "Continue",
        },
      },
      mayorSuccessor: {
        title: "Mayor elects successor",
        selectSuccessor: {
          instructions:
            "Your last act as Mayor is to select who from the village is worthy of being your successor.",
          hint: "Select the player you choose as the next Mayor and press select.",
          submitLabel: "Select",
        },
        forInactivePlayers: {
          instructions:
            "The Mayor is on their death bed. With their last breath they selects their successor.",
        },
        outcome: {
          instructions:
            "The Mayor has died and selected ${name} as their successor.",
          submitLabel: "Continue",
        },
      },
      lynch: {
        title: "Lynching",
        voting: {
          instructions:
            "The village comes together fueled by anger. In an attempt to rid the village of the werewolves, they hold a vote of who to kill.",
          hint: "Pick the player you wish to vote for and tap vote.",
          submitLabel: "Vote",
        },
        forInactivePlayers: {
          instructions:
            "The village comes together fueled by anger. In an attempt to rid the village of the werewolves, they hold a vote of who to kill.",
          hint: "You are unable to take part. Wait for the others to cast their votes.",
        },
        waitForOthers: {
          instructions:
            "You vote has been cast. Now wait for others to cast theirs.",
        },
        outcome: {
          instructions:
            "The votes are counted and the mob descends upon ${victim}.",
          submitLabel: "Continue",
          waitForOtherHint: "Wait for other to continue the game.",
        },
        outcomeUndecided: {
          instructions:
            "The village is unable to decide who will be the next lynch victim. The Mayor will now be consulted and decide who will be the victim.",
          submitLabel: "Continue",
        },
      },
      mayorDecide: {
        title: "Mayor decision",
        votingToDecideVictim: {
          instructions:
            "As the leader of the village, it is upon you to use wise judgement and decide who the werewolf could be.",
          hint: "Pick the player you wish to vote for and tap 'Pick villager'",
          submitLabel: "Pick villager",
        },
        forInactivePlayers: {
          instruction: "Wait until the Mayor has made the decision.",
        },
        result: {
          instruction:
            "The Mayor has decided and the mob descends upon ${victim}.",
          submitLabel: "Continue",
        },
      },
      end: {
        werewolvesWon: {
          instructions: "The village lies in ruins, the werewolves have won!",
        },
        villageWon: {
          instructions:
            "The feast begins. The villagers have defeated the werewolves who have plagued them for centuries.",
        },
        loversWon: {
          instructions:
            "Against all odds, the lovers prevail and live happily ever after.",
        },
      },
    },
  },
};

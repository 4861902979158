import { Translation } from "../../localization/components/translations";
import { FullHeight } from "../components/full-height";
import { LoadingPage } from "./loading";

export function LoadingApp() {
  return (
    <FullHeight>
      <LoadingPage>
        <Translation k="loading.headline" />
      </LoadingPage>
    </FullHeight>
  );
}

import { english } from "../translations/english";
import { german } from "../translations/german";

export type Language = typeof english;

export const SUPPORTED_LANGUAGES = {
  en: english,
  de: german,
};

export type SupportedLanguage = keyof typeof SUPPORTED_LANGUAGES;

import { Translation } from "../../localization/components/translations";
import { ErrorPage } from "../../ui/pages/error";

export function GameErrorPage() {
  return (
    <ErrorPage>
      <div>
        <Translation k="error.headline" />
      </div>
      <div className="text-xs text-gray-300 mt-2">
        <Translation k="error.hint" />
      </div>
    </ErrorPage>
  );
}

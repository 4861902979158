import { Language } from "../utils/supported-languages";

export const german: Language = {
  loading: {
    headline: "Loading",
  },
  welcome: {
    headline: "Start",
    subtitle: "Erstelle einen Raum, lade deine Freunde ein und habt Spaß!",
  },
  error: {
    headline: "Wenn du das hier liest, habe ich Scheiße gebaut.",
    hint: "Leider kann ich noch nicht behilflich sein beim beheben des Problems. Diese Runde kannst du leider nicht mehr beenden. Du kannst jedoch einen neuen Raum erstellen und dort weiterspielen.",
  },
  pageNotFound: {
    headline: "Das ist nicht die Seite, nach der du gesucht hast.",
    linkHomeLabel: "Home",
  },
  room: {
    loading: {
      headline: "Vorbereitungen laufen",
    },
    playerIsJoining: {
      headline: "Raum wird betreten",
    },
    loadingGame: {
      headline: "Das Spiel wird geladen",
    },
    waiting: {
      video: {
        connecting: "Verbinden",
      },
      readyButton: {
        label: "Bereit",
        subtitle: "Tippe auf diesen Button, wenn alle Spieler im Raum sind.",
      },
      notEnoughPlayersWarning: {
        explanation: "Es befinden sich nicht genügend Spieler im Raum (min 6).",
      },
      inviteButton: {
        label: "Einladen",
        subtitle: "Lade andere Leute ein dem Raum beizutreten.",
      },
      waitingForOtherPlayersNotice: {
        explanation: "Warte auf ${number} andere Spieler.",
      },
    },

    gameEnd: {
      waitingForOthers:
        "Warte bis die Anderen sich entschieden haben ein weiteres Spiel zu spielen oder den Raum zu verlassen.",
    },
  },
  captureInformation: {
    headline: "Raum beitreten",
    invitedBy: "${name} hat dich eingeladen.",
    description:
      "Gib deinen Namen an und erlaube Zugriff auf deine Kamera, bevor du den Raum betreten kannst.",
    explanationLabel: "Was ist diese?",
    enableVideoLabel: "Kamera erlauben",
    deniedVideoPermissionWarning:
      "Du hast Zugriff auf die Kamera blockiert. Um dem Raum zu betreten musst du dies zulassen.",
    submitLabel: "Beitreten",
  },
  games: {
    werwolf: {
      roles: {
        werwolf: "Werwolf",
        seer: "Seherin",
        mage: "Hexe",
        cupid: "Amor",
        hunter: "Jäger",
        villager: "Dorfbewohner",
      },
      loading: {
        headline: "Spiel wird vorbereitet",
      },
      announceDeath: {
        roleReveal: "${name} war ein/e ${role}.",
        loverReveal: "${name} war Liebender von ${loverName}.",
      },
      opening: {
        title: "Rollenverteilung",
        basic: {
          gameExplanation:
            "Sei gewarnt, in diesem Spiel können deine Mitspieler dich anlügen!",
          submitLabel: "Start",
          questLabel: "Quest",
          abilitiesLabel: "Fähigkeiten",
        },
        spectator: {
          title: "Du bist ein Zuschauer!",
          explanation:
            "Leider bist du dem Spiel zu spät beigetreten um noch aktiv daran teilzunehmen. Du kannst dem Spielverlauf dennoch folgen.",
        },
        seer: {
          title: "Du bist die Seherin!",
          explanationsQuest:
            "Deine Aufgabe ist es, die Werwölfe ausfindig zu machen und diese zu lynchen!",
          explanationAbilities:
            "Du hast die Fähigkeit, jede Nacht die ware Identität eines Dorfbewohners herauszufinden.",
        },
        cupid: {
          title: "Du bist Amor!",
          explanationsQuest:
            "Deine Aufgabe ist es, die Werwölfe ausfindig zu machen und diese zu lynchen!",
          explanationAbilities:
            "Du schießt den Pfeil der Liebe, um 2 Herzen zu verbinden. Falls einer sterben sollte, so stirb auch der Andere; um zu gewinnen, müssen beide am Leben bleiben.",
        },
        hunter: {
          title: "Du bist ein Jäger!",
          explanationsQuest:
            "Deine Aufgabe ist es, die Werwölfe ausfindig zu machen und diese zu lynchen!",
          explanationAbilities:
            "Falls dich das Zeitliche segnen sollte, so kannst du einen letzten Pfeil schießen.",
        },
        villager: {
          title: "Du bist ein Dorfbewohner!",
          explanationsQuest:
            "Deine Aufgabe ist es, die Werwölfe ausfindig zu machen und diese zu lynchen!",
          explanationAbilities:
            "Ein Dorfbewohner hat keine besonderen Fähigkeiten.",
        },
        wolf: {
          title: "Du bist ein Werwolf!",
          explanationsQuest:
            "Deine Aufgabe ist es, unbemerkt unter den Dorfbewohnern zu leben, um sie einen nach dem Anderen umzubringen",
          otherWerewolvesSingular: "${werewolf} ist ebendfalls ein Werwolf.",
          otherWerewolvesPlural: "${werewolves} sind ebendfalls Werwölfe.",
          explanationAbilities:
            "Bei Nacht, dürstet es dir nach Blut und ein unschuldiger Dorfbewohner muss sterben um diesen zu stillen.",
        },
        mage: {
          title: "Du bist die Hexe!",
          explanationsQuest:
            "Deine Aufgabe ist es, die Werwölfe ausfindig zu machen und diese zu lynchen!",
          explanationAbilities:
            "Deine hexerischen Fahigkeiten erlauben es dir einmalig ein Opfer vor den Werwölfen zu retten. Ebendso bist du in Besitzes eines Gifttranks, setze ihn klug ein um das Dorf zu beschützen.",
        },
        waitingForOthers: {
          instructions: "Warte auf die Anderen um das Spiel zu beginnen.",
        },
      },
      mayorVote: {
        title: "Wahl des/der Bürgermeisters/Bürgermeisterin",
        voting: {
          instructions:
            "Das Dorf kommt zusammen um ihren nächsten Bürgermeister/in zu wählen.\n Der/Die Bürgermeister/in wird im Falle eines Gleichstandes bei der Wahl des Lynchopfers entscheiden wer sterben muss. Im Todesfalle des/der Bürgermeisters/Bürgermeisterin wird dieser/diese die Nachfolge selbst bestimmen.",
          hint: "Wähle den Spieler aus den du wählen möchtest und tippe auf 'Wählen'.",
          waitForOthers: {
            instructions:
              "Dein Stimmzettel wurde abgegeben! Warte auf die Anderen.",
          },
          submitLabel: "Wählen",
        },
        result: {
          instructions:
            "Die Wahl ist beendet. Das Dorf has ${mayor} zum/zur Bürgermeister/in gewählt.",
          votesHeadline: "Stimmen",
          submitLabel: "Weiter",
          waitForOthers: {
            instructions: "Warte auf die Anderen.",
          },
        },
      },
      wolves: {
        title: "Werwölfe",
        voting: {
          instructions:
            "Du und die anderen Werwölfe erwachen! Wählt euer nächstes Opfer aus.",
          hint: "Wähle den Spieler aus der das nächste Opfer werden soll.",
          submitLabel: "Auswählen",
        },
        waitingForOthers: {
          instructions: "Warte auf die anderen Werwölfe.",
        },
        outcome: {
          instructions:
            "Die Werwölfe haben ${victim} als ihr nächstes Opfer ausgewählt.",
          submitLabel: "Weiter",
          waitingForOthersHint:
            "Warte auf die anderen Werwölfe um die Runde zu beenden.",
        },
        waitForWolves: {
          instructions:
            "Als die Sonne am Horizont verschwindet legte sich die Dunkelheit über das Dorf. Die Werwölfe erwachten und fassten ihr nächstes Opfer ins Auge.",
        },
      },
      seer: {
        title: "Die Seherin",
        voting: {
          instructions:
            "Du erwachst. Dein inneres Auge gibt die wahre Identität eines Dorfbewohners preis.",
          hint: "Wähle einen Spieler aus um zu erfahren ob er/sie ein Werwolf ist.",
          submitLabel: "Auswählen",
        },
        result: {
          isWerwolf: "Du has ${name} ausgewählt. ${name} ist ein Werwolf.",
          isNotWerwolf: "Du has ${name} ausgewählt. ${name} ist kein Werwolf.",
          submitLabel: "Weiter",
        },
        waitForSeer: {
          instructions:
            "Die Seherin erwacht, um die Identität eines Werwolfs zu enttarnen!",
          hint: "Warte bis die Seherin ihre Runde abgeschlossen hat.",
        },
        deadSeer: {
          instructions:
            "Die Seherin ist tot und mit ihr die Möglichkeit einen Werwolf zu demaskieren!",
          hint: "Das Spiel wird automatisch fortgesetzt.",
        },
      },
      mage: {
        title: "Die Hexe",
        saveWolvesVictim: {
          instructions:
            "Du erwachst und erfährst, wer das Opfer der Wölfe ist. ${victim} soll das nächste Opfer werden. Möchtest du ihn/sie retten?",
          hint: "Du kannst diese Fahigkeit nur einmal einsetzen.",
          yesSubmitLabel: "Ja",
          noSubmitLabel: "Nein",
        },
        usedSavePower: {
          instructions:
            "Du erwachst, doch du hast deine Fahigkeit jemanden vor den Werwölfen zu retten bereit genutzt.",
          submitLabel: "Weiter",
        },
        poisonDecision: {
          instructions: "Möchtest du deinen Gifttrank verwenden?",
          hint: "Du hast nur einen Gifttrank und kannst diesen somit nur einmal einsetzten.",
          yesSubmitLabel: "Ja",
          noSubmitLabel: "Nein",
        },
        poisonAbilityHasBeenUsed: {
          instructions: "Du hast keine Gifttränke mehr.",
          submitLabel: "Weiter",
        },
        poisonSelect: {
          instructions: "Wen möchtest du den Gifttrank verabreichen?",
          hint: "Wähle den Spieler aus den du vergiften und damit töten möchtest.",
          submitButton: "Auswählen",
        },
        result: {
          savingAbility: {
            alreadyUsed:
              "Du konntest das Opfer nich vor den Werewölfen schützen.",
            used: "Du hast das Opfer der Werewölfe erfolgreich gerettet.",
            notUsed:
              "Du hast dich dazu entschieden das Opfer nicht vor den Werwölfen zu schützen.",
          },
          poisonAbility: {
            alreadyUsed: "Du hattest keinen Giftrank mehr übrig.",
            used: "Du hast deinen Gifttrank ${name} verabreicht, er/sie wird einen schmerzhaften Tod sterben.",
            unused:
              "Du hast deinen Gifttrank für eine zukünftige Nacht aufgespart.",
          },
          submitLabel: "Weiter",
        },
        waitForMage: {
          instructions: "Die Hexe erwacht.",
          hint: "Warte bis die Hexe ihr Hexenwerk vollbracht hat.",
        },
        deadMage: {
          instructions:
            "Die Hexe ist bereits verstorben und kann somit niemanden schützen oder vergiften.",
          hint: "Das Spiel wird automatisch fortgesetzt.",
        },
      },
      cupid: {
        title: "Amor",
        pickLovers: {
          instructions:
            "Du erwachst. Nun finde 2 Dorfbewohner/innen dessen Herzen du einen möchstest! Falls einer der Beiden sterben sollte, so wird auch der/die Andere sein/ihr Ende finden; um zu gewinnen, müssen beide überleben.",
          hint: "Wähle die 2 Verliebten aus, du kannst dich auch selbst auswählen.",
          submitLabel: "Auswählen",
        },
        outcome: {
          instructions:
            "Dein Pfeil trift in die Herzen von ${loverOne} und ${loverTwo}!",
          submitLabel: "Weiter",
        },
        waitForCupid: {
          instructions:
            "Amor erwacht um 2 Herzen miteinander zu vereinen. Falls einer der Beiden sterben sollte, so wird auch der/die Andere sein/ihr Ende finden; um zu gewinnen, müssen beide überleben.",
          hint: "Warte bis Amor seine Runde beendet hat.",
        },
      },
      lovers: {
        title: "Die Liebenden",
        loversMeet: {
          instructions:
            "Amors Pfeil hat dich getroffen. Du erwachst und erblickst das Angesicht deiner Liebe. Eure Aufgabe ist nun als einzige im Dorf zu überleben. Falls einer von euch sterben sollte, so wird auch der Andere sein Leben verlieren.",
          hint: "Denkt euch nun eine Strategie aus, ihr werden nicht noch einmal ungestört reden konnen, bis zum Ende des Spiels.",
          submitLabel: "Weiter",
        },
        waitForLovers: {
          instructions: "Die Liebenden erwachen.",
          hint: "Warte bis die Liebenden fertig sind mit ihrere Runde.",
        },
      },
      morning: {
        title: "Der Morgen",
        nightOver: {
          instructions:
            "Die ersten Sonnenstrahlen vertreiben die Dunkelheit der Nacht. Doch was ist während der Nacht geschehen?",
          submitLabel: "Weiter",
        },
        wolvesVictim: {
          mageNotUsedSaveAbility:
            "Du siehts wie sich eine Menge bilded auf dem Dorfplatz. ${name} lag regungslos in der Mitte des Menge. Er/Sie war also das jüngste Opfer der Werwölfe, da die Hexe sich dazu entschieden hat, ihn/sie zu retten.",
          mageUsedSaveAbility:
            "Die mächtige und weise Hexe hat unter Verwendung ihrer magischen Fahigkeiten verhindert, dass die Werwölfe ein weiteres Opfer einfordern konnten.",
          mageHasAlreadyUsedSaveAbility:
            "Du siehts wie sich eine Menge bilded auf dem Dorfplatz. ${name} lag regungslos in der Mitte des Menge. Er/Sie war also das jüngste Opfer der Werwölfe, denn selbst die Hexe konnte ihn/sie nicht mehr retten.",

          submitLabel: "Weiter",
        },
        magePoison: {
          mageNotUsedPoisonAbility:
            "Die Hexe hatte sich entschieden nicht von ihrem Gifttrank gebrauch zu machen.",
          mageUsedPoisonAbility:
            "Die Hexe hat sich entschieden ihren Giftrank zu verwenden um ${name} zu vergiften! ${name} ist eines grausamen Todes gestorben.",
          mageHasAlreadyUsedPoisonAbility:
            "Die Hexe hatte ihren Gifttrank schon einmal verwendet und konnte dies nicht noch einmal tun.",
          submitLabel: "Weiter",
        },
        waitForOthers: {
          instructions: "Warte auf die Anderen.",
        },
      },
      hunter: {
        title: "Der Jäger",
        lastShot: {
          instructions:
            "Dem unvermeidlichem Tode nahe, nimmt du Pfeil und Bogen in die Hand und schießt deinen letzten Schuss.",
          hint: "Wähle den Spieler aus, der ebendfalls aus dem Spiel ausscheiden soll.",
          submitLabel: "Auswählen",
        },
        waitForHunter: {
          instruction:
            "Dem unvermeidlichem Tode nahe, ${hunter} spannt seinen/ihren Bogen ein letztes mal.",
          hint: "Warte auf den Jäger.",
        },
        outcome: {
          instructions: "${hunter} schießt und trifft ${name}.",
          submitLabel: "Weiter",
        },
      },
      mayorSuccessor: {
        title: "Nachfolge des/der Bürgermeisters/Bügermeisterin",
        selectSuccessor: {
          instructions:
            "Mit deinem lezten Atemzug ernennst du deinen/deine eigenen/eigene Nachfolger/in.",
          hint: "Wähle den Spieler aus, der der/die nächster/nächste Bürgermeister/in werden soll.",
          submitLabel: "Auswählen",
        },
        forInactivePlayers: {
          instructions:
            "Der/Die Bürgermeister/in liegt auf seinem/ihren Sterbebet. Mit seinem/ihrem leztem Atemzug, ernnent er/sie seine/ihre Nachfolger/in",
        },
        outcome: {
          instructions:
            "Der/Die Bürgermeister/in ist tot. ${name} wird nun seine/ihre Nachfolge antreten.",
          submitLabel: "Weiter",
        },
      },
      lynch: {
        title: "Das Lynchen",
        voting: {
          instructions:
            "Das Dorft sammlet sich auf dem Dorfplatz. Die Menge ist erzürnt über die frei laufenden Werwölfe. Um das Dorf von der Plage der Werwölfe zu befreien wird zu einer Wahl ausgerufen. Derjenige der die meisten Stimmen bekommt, wird auf der Stelle gehängt.",
          hint: "Wähle den Spieler für den du stimmen möchtest und tippe auf 'Abstimmen'",
          submitLabel: "Abstimmen",
        },
        forInactivePlayers: {
          instructions:
            "Das Dorft sammlet sich auf dem Dorfplatz. Die Menge ist erzürnt über die frei laufenden Werwölfe. Um das Dorf von der Plage der Werwölfe zu befreien wird zu einer Wahl ausgerufen. Derjenige der die meisten Stimmen bekommt, wird auf der Stelle gehängt.",
          hint: "Du kannst leider nicht an dieser Abstimmung teilnehmen. Warte bis alle Anderen ihre Stimme abgegebnen haben.",
        },
        waitForOthers: {
          instructions:
            "Dein Stimmzettel wurde abgegeben. Nun warte auf die anderen Dorfbewohner.",
        },
        outcome: {
          instructions:
            "Das Wahl ist nun abgeschlossen, das Dorf stützt sich auf ${victim} und ziehen ihn/sie zum Galgen.",
          submitLabel: "Weiter",
          waitForOtherHint: "Warte auf die Anderen.",
        },
        outcomeUndecided: {
          instructions:
            "Die Wahl ist nun abgeschlossen. Das Dorf ist gespalten. Der/Die Bürgermeister/in wird nun zu Rat gezogen um eine entgültige Entscheidung zu treffen.",
          submitLabel: "Weiter",
        },
      },
      mayorDecide: {
        title: "Die Entscheidung",
        votingToDecideVictim: {
          instructions:
            "Als der/die Anfüher/in des Dorfes ist es nun an dir zu entscheiden, wer gehängt werden soll. Höre die nun die Argumente aller Beteiligten an um eine Entschediung zu fällen.",
          hint: "Wähle den Spieler aus der gehängt werden soll.",
          submitLabel: "Auswählen",
        },
        forInactivePlayers: {
          instruction:
            "Der/Die Bürgermeister/in entscheiden nun über das Schicksal der Dorfbewohner, die gleich viele Stimmen erhalten haben. Du kannst ihn/sie durch deine Überzeugungskunst beinflussen.",
        },
        result: {
          instruction:
            "Die Entscheidung ist gefallen. Der/Die Bürgermeistern verkündete laut den Namen der Person, die als nächstes hägen wird. '${victim}' erschallte laut über den Dorfplatz.",
          submitLabel: "Weiter",
        },
      },
      end: {
        werewolvesWon: {
          instructions:
            "Eine ewige anhaltende Dunkelheit legte sich über das Dorf. Die Werwölfe haben gewonnen!",
        },
        villageWon: {
          instructions:
            "Lasset das Fest beginnen. Das Dorf hat die Werwölfe besiegt, die sie so lange in Angst und Schrecken versetzt hatten.",
        },
        loversWon: {
          instructions:
            "Entgegen aller Schwierigkeiten, die Liebenden haben überlebt und lebten glücklich bis in alle Ewigkeit.",
        },
      },
    },
  },
};
